// .storybook/YourTheme.js

import { create } from '@storybook/theming';

export default create({
    base: 'light',
    colorPrimary: 'skyblue',
    colorSecondary: 'deepskyblue',

    // UI
    appBg: 'white',
    appContentBg: 'white',
    appBorderColor: 'rgba(54,53,53,0.9)',
    appBorderRadius: 4,

    // Typography
    fontBase: '"Open Sans", sans-serif',
    fontCode: 'monospace',

    // Text colors
    textColor: 'rgba(54,53,53,0.9)',
    textInverseColor: 'rgba(255,255,255,0.9)',

    // Toolbar default and active colors
    barTextColor: 'silver',
    barSelectedColor: 'rgba(54,53,53,0.9)',
    barBg: 'white',

    // Form colors
    inputBg: 'white',
    inputBorder: 'rgba(201,198,198,0.9)',
    inputTextColor: 'rgba(54,53,53,0.9)',
    inputBorderRadius: 4,

    brandTitle: '@D3v4pp/ui',
    brandUrl: 'https://d3v4pp.fr',
    brandImage: 'https://statics.d3v4pp.fr/logo.png',
    brandTarget: '_self'
});